import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import Website from './pages/Website'
import Privacy from './pages/Privacy'
import Welcome from './pages/Welcome'
import Portal from './pages/Portal'
import Financials from './pages/Financials'
import Authorized from './components/Authorized'
import Private from './components/Private'
import Footer from './components/Footer'
import TeamsProvider from './contexts/teams-context'
import { ROUTES } from './constants/routes'

import './App.scss'

import './assets/fonts/Rajdhani-Light.ttf'
import './assets/fonts/Rajdhani-Medium.ttf'
import './assets/fonts/Rajdhani-Regular.ttf'
import './assets/fonts/Rajdhani-SemiBold.ttf'
import './assets/fonts/Rajdhani-Bold.ttf'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={ROUTES.WEBSITE.PATH} element={<Website />} />

          <Route path={ROUTES.PRIVACY.PATH} element={<Privacy />} />

          <Route path={ROUTES.WELCOME.PATH} element={<Welcome />} />

          <Route path='/.well-known/apple-app-association-file' element={<div></div>} />

          <Route
            path={`${ROUTES.PORTAL.PATH}/*`}
            element={
              <TeamsProvider>
                <Private />
                <Footer />
              </TeamsProvider>
            }
          >
            <Route index element={<Portal />} />

            <Route path={ROUTES.FINANCIALS.PATH} element={<Financials />} />

            {/* wild route*/}
            <Route
              path={`${ROUTES.PORTAL.PATH}/*`}
              element={<Navigate to={ROUTES.PORTAL.PATH} replace />}
            />
          </Route>

          <Route
            key={ROUTES.AUTHORIZED.KEY}
            path={ROUTES.AUTHORIZED.PATH}
            element={<Authorized />}
          />

          {/* wild route*/}
          <Route path='*' element={<Navigate to={ROUTES.WEBSITE.PATH} replace />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
